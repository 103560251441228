<template>
  <div class="detail">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <!-- <h1>All new learning experience</h1> -->
            <div v-if="loading" class="loading">Loading...</div>
            <h1 v-else-if="event">{{ event.title }}</h1>
            <p v-if="event" v-html="event.short_description"></p>
            <ul v-if="event" class="list-unstyled">
              <li>Host : {{ event.host }}</li>
              <li>Total subscribers allowed : {{ event.total_subscribers }}</li>
            </ul>
            <!-- <p v-if="event">
              <span>Start Date :</span>
              {{ getDate(event.start_time) }}
            </p>
            <p v-if="event">
              <span>End Date :</span>
              {{ getDate(event.end_time) }}
            </p> -->
          </div>
          <div class="col-md-5">
            <div v-if="event" class="video-wrap">
              <img
                v-if="event.banner != null"
                :src="event.banner"
                class="card-img-top"
                alt=""
              />
              <img
                v-else
                src="../assets/explr-banner-img.png"
                class="card-img-top"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div class="offdrop-box">
          <div v-if="loading" class="loading">Loading...</div>
          <div class="price" v-else-if="event">
            <h4 v-if="event.price.final_price > 0">
              ₹ {{ event.price.final_price }}
            </h4>
            <!-- <span>per year</span> -->
          </div>
          <router-link
            v-if="
              event && event.purchase_status === true && event.is_external == 0
            "
            :to="{ name: 'EventCheckout', params: { event_id: event.id } }"
          >
            <a v-if="event.price.final_price > 0" class="btn cta-primary"
              >buy now</a
            >
            <a v-else class="btn cta-primary">book now</a>
          </router-link>
          <a
            class="btn cta-primary"
            v-if="event && event.is_external == 1"
            @click="redirectLink(event.external_link)"
          >
            book now <i class="fas fa-external-link-alt"></i>
          </a>
          <small
            class="cta-info"
            v-if="
              event && event.purchase_status === false && event.is_external == 0
            "
          >
            {{ event.purchase_status_comment }}
          </small>
        </div>
      </div>
    </div>

    <div class="highlight-wrapper">
      <div class="container">
        <!-- <h2>class highlight</h2>
        <div class="row">
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
          <div class="col-md-4">
            <div class="highlight-card">
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit.
                Exercitation veniam consequat sunt nostrud amet.
              </p>
            </div>
          </div>
        </div> -->
        <div class="overview">
          <h2>Event overview</h2>
          <div v-if="loading" class="loading">Loading...</div>
          <p v-else-if="event" v-html="event.description"></p>
        </div>
      </div>
    </div>
    <FAQ />
    <div class="review-wrapper">
      <div class="experience-wrapper container">
        <Testimonial
          v-if="sortedTestimonials.length > 0"
          :sortedTestimonials="sortedTestimonials"
        />
      </div>
    </div>

    <CareerCounselling />
  </div>
</template>

<script>
import EventService from "@/services/EventService";
import CommonService from "@/services/CommonService";
import Testimonial from "@/components/Testimonial.vue";
import FAQ from "@/components/FAQ.vue";
import CareerCounselling from "@/components/CareerCounselling.vue";
import Timezone from "@/services/Timezone";

export default {
  name: "EventDetail",
  components: {
    Testimonial,
    FAQ,
    CareerCounselling,
  },
  data() {
    return {
      loading: true,
      params: {
        // event_id: this.$route.params.event_id,
        slug: this.$route.params.slug,
      },
      // Note:- declare event: null, if v-if="event" is going to use
      event: null,
      sortedTestimonials: [],
    };
  },
  mounted() {
    // Note:- Append the pixel code script for this page to head tag only for production env
    if (process.env.NODE_ENV === "production") {
      let pixelCodeForEvents = document.createElement("script");
      pixelCodeForEvents.innerHTML =
        "!function(f,b,e,v,n,t,s)" +
        "{if(f.fbq)return;n=f.fbq=function(){n.callMethod?" +
        "n.callMethod.apply(n,arguments):n.queue.push(arguments)};" +
        "if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';" +
        "n.queue=[];t=b.createElement(e);t.async=!0;" +
        "t.src=v;s=b.getElementsByTagName(e)[0];" +
        "s.parentNode.insertBefore(t,s)}(window, document,'script'," +
        "'https://connect.facebook.net/en_US/fbevents.js');" +
        "fbq('init', '216933973926923');" +
        "fbq('track', 'PageView');";
      document.head.appendChild(pixelCodeForEvents);
    }
  },
  created() {
    // console.log(this.$route);
    this.loadEvent();
    this.fetchTestimonials();
  },
  methods: {
    async loadEvent() {
      await EventService.getEventData(this.params)
        .then((response) => {
          this.loading = false;
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.event = response.data.event;
          }
          if (response.data.status === "ERROR") {
            this.$router.push({
              name: "NotFound",
              // preserve current path and remove the first char to avoid the target URL starting with `//`
              params: { pathMatch: this.$route.path.substring(1).split("/") },
              // preserve existing query and hash if any
              // query: this.$route.query,
              // hash: this.$route.hash,
            });
            // this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
          // console.log(this.event);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    redirectLink(link) {
      // console.log(link);
      window.open(link, "_blank");
    },
    getDate(date) {
      return Timezone.convertedTimeZone(date).format(
        "ddd, Do MMM, YYYY h:mm a"
      );
    },
    fetchTestimonials() {
      CommonService.getTestimonials()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            let testimonials = response.data.testimonials;
            let count = testimonials.length;
            let j = 0;
            let k = 0;
            for (let i = 1; i <= count; i++) {
              j = k;
              k = k + 2;
              if (j >= count) {
                break;
              } else {
                this.sortedTestimonials.push(testimonials.slice(j, k));
              }
            }
            // console.log(this.sortedTestimonials);
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@/style/course-detail.scss";
</style>
