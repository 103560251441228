<template>
  <div class="faq-wrapper">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="graphic">
            <img src="../assets/faq-img.png" alt="" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="accordion-wrap">
            <h3>FAQ</h3>
            <div class="accordion accordion-flush" id="accordionFlushFaq">
              <div class="accordion-item" v-for="faq in faqs" :key="faq.id">
                <h2 class="accordion-header" :id="'flush-heading' + faq.id">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#flush-collapse' + faq.id"
                    aria-expanded="false"
                    :aria-controls="'flush-collapse' + faq.id"
                  >
                    {{ faq.question }}
                  </button>
                </h2>
                <div
                  :id="'flush-collapse' + faq.id"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'flush-heading' + faq.id"
                  data-bs-parent="#accordionFlushFaq"
                >
                  <div class="accordion-body" v-html="faq.answer"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonService from "@/services/CommonService";

export default {
  name: "FAQ",
  data() {
    return {
      faqs: [],
    };
  },
  created() {
    this.fetchFAQs();
  },
  methods: {
    fetchFAQs() {
      CommonService.getFAQs()
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.faqs = response.data.faqs;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
